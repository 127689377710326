
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import CashBookComponent from "@/components/finance/definitions/cash-book.vue"
import { CashBook } from "@/core/models/CashBook"
import { SelectModel } from "@/core/models/SelectModel"

import swalConfirm from "@/core/helpers/swal-confirm"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "cash-book-list",
  components: { CashBookComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const newCashBook = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])

    const cashBookList = ref<CashBook[]>([] as CashBook[])
    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const activeCashBookId = ref<string | undefined>()

    const currencyList = ref([
      {
        id: 1,
        name: "TL",
      },
      {
        id: 2,
        name: "Dolar",
      },
      {
        id: 3,
        name: "Euro",
      },
    ])

    watch(
      () => route.params.cash_book_id,
      newValue => {
        activeCashBookId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newCashBook.value = true == newValue
      }
    )

    onMounted(async () => {
      activeCashBookId.value = route.params.cash_book_id as string
      newCashBook.value = props.add
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getBranchList = async businessId => {
      if (businessId != undefined) {
        isBranchSelectLoading.value = true
        const config = {
          params: {
            businessId: businessId,
          },
        }
        const { data } = await ApiService.query("branch", config)
        branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
        isBranchSelectLoading.value = false
      }
    }

    const getCashBookList = async (businessId, branchId) => {
      if (businessId == undefined && (branchId == undefined || branchId == "")) return
      else if (branchId != undefined && branchId.length != 0) {
        isTableDataLoading.value = true
        activeBusinessId.value = businessId != undefined ? businessId : activeBusinessId.value
        await getBranchList(businessId)
        activeBranchId.value = branchId
        const { data } = await ApiService.get("cash-book/branch/" + activeBranchId.value)
        cashBookList.value = data.map(x => ({ ...x, bankAccount: { ...x.bankAccount } }))
        isTableDataLoading.value = false
      } else {
        isTableDataLoading.value = true
        activeBusinessId.value =
          activeBusinessId.value == undefined ? businessId : activeBusinessId.value
        activeBranchId.value = undefined
        const { data } = await ApiService.get("cash-book/business/" + businessId)
        cashBookList.value = data.map(x => ({ ...x, bankAccount: { ...x.bankAccount } }))
        isTableDataLoading.value = false
      }
    }

    const OnChangeBusiness = businessId => {
      activeBranchId.value = undefined
      getBranchList(businessId)
      getCashBookList(businessId, activeBranchId.value)
    }

    const removeCashBook = async cashBookId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("cash-book/remove/" + cashBookId)
        await getCashBookList(activeBusinessId.value, activeBranchId.value)
      }
    }

    const handleCashBookDrawerClose = () => {
      router.push({ name: "cash-book-list" })
    }

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      cashBookList,
      businessList,
      branchList,
      activeBusinessId,
      activeBranchId,
      Edit,
      removeCashBook,
      getCashBookList,
      activeCashBookId,
      newCashBook,
      handleCashBookDrawerClose,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      OnChangeBusiness,
      currencyForm,
      currencyList,
    }
  },
})
