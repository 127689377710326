
import { SelectModel } from "@/core/models/SelectModel"
import { CashBook } from "@/core/models/CashBook"
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { BankAccountType } from "@/core/models/BankAccountType"
import { BankAccount } from "@/core/models/BankAccount"
import { staticCurrencyList } from "@/core/enums/enums"

export default defineComponent({
  name: "cashbook-component",
  components: {},
  props: ["add", "id", "closed", "activeBusiness", "activeBranch"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const cashBookData = ref<CashBook>({} as CashBook)

    type FormInstance = InstanceType<typeof ElForm>

    //TODO: To be refactored
    const ruleFormRef = ref<FormInstance>()
    const isBranchActive = ref<boolean>(false)
    const branchName = ref<string>()
    const branchCode = ref<string>()
    const accountNumber = ref<string>()
    const ibanNumber = ref<string>()
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isBankSelectLoading = ref(false)
    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const activeBankAccountType = ref<number>(0)
    const activeBankId = ref<string>()
    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const bankList = ref<SelectModel[]>([])
    const currencyList = ref(staticCurrencyList)
    const bankAccountTypeList = ref([
      {
        value: 0,
        name: "Nakit",
      },
      {
        value: 1,
        name: "Pos",
      },
      {
        value: 2,
        name: "Havale/EFT",
      },
      {
        value: 3,
        name: "Çek",
      },
    ])

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      branchId: getRule(RuleTypes.SELECT, "Şube"),
      name: getRule(RuleTypes.TEXT100, "Ad"),
      currencyUnit: getRule(RuleTypes.SELECT, "Para Birimi"),
      "bankAccount.bankId": getRule(RuleTypes.SELECT, "Banka"),
      "bankAccount.branchName": getRule(RuleTypes.TEXT50, "Şube Adı"),
      "bankAccount.branchCode": getRule(RuleTypes.TEXT50, "Şube Kodu"),
      "bankAccount.accountNumber": getRule(RuleTypes.TEXT50, "Hesap Numarası"),
      "bankAccount.ibanNumber": getRule(RuleTypes.IBAN),
      "bankAccount.holdOverDay": getRule(RuleTypes.NUMBER, "Bekleme Günü"),
      "bankAccount.commissionRate": getRule(RuleTypes.NUMBER, "Kesinti Oranı"),
    })

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          getCashBook(newValue)
          ruleFormRef.value?.resetFields()
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          resetCashBookData()
        }
      }
    )

    watch(
      () => activeBankAccountType.value,
      newValue => {
        if (newValue === 1 && !cashBookData.value.bankAccount?.holdOverDay) {
          cashBookData.value = {
            ...cashBookData.value,
            bankAccount: {
              ...cashBookData.value.bankAccount,
              holdOverDay: 0,
              commissionRate: 0,
            } as BankAccount,
          } as CashBook
        }
      }
    )

    const resetCashBookData = () => {
      cashBookData.value = {} as CashBook
      activeBankAccountType.value = 0
      //TODO: To be refactored
      activeBankId.value = undefined
      branchCode.value = undefined
      branchName.value = undefined
      ibanNumber.value = undefined
      accountNumber.value = undefined

      if (props.activeBranch != undefined && props.activeBranch != "") {
        activeBusinessId.value = props.activeBusiness
        cashBookData.value.businessId = props.activeBusiness as string
        isBranchActive.value = true
        OnChangeBusiness(activeBusinessId.value)
        activeBranchId.value = props.activeBranch
        cashBookData.value.branchId = props.activeBranch as string
      } else {
        isBranchActive.value = false
        activeBranchId.value = undefined
        activeBusinessId.value = props.activeBusiness
        cashBookData.value.businessId = props.activeBusiness as string
        cashBookData.value.branchId = undefined
      }
      ruleFormRef.value?.resetFields()
    }

    const getCashBook = async id => {
      const { data } = await ApiService.get("cash-book/" + id)

      cashBookData.value = data
      cashBookData.value.branchId = data["brancId"] as string
      if (data.branchId != "00000000-0000-0000-0000-000000000000") {
        isBranchActive.value = true
        getBranch(cashBookData.value.branchId)
        activeBranchId.value = cashBookData.value.branchId
      } else {
        isBranchActive.value = false
        activeBranchId.value = undefined
        data["brancId"] = undefined
      }
      activeBankAccountType.value = cashBookData.value.bankAccount?.bankAccountType ?? 0
      activeBankId.value = cashBookData.value.bankAccount?.bankId
      branchName.value = cashBookData.value.bankAccount?.branchName
      branchCode.value = cashBookData.value.bankAccount?.branchCode
      accountNumber.value = cashBookData.value.bankAccount?.accountNumber
      ibanNumber.value = cashBookData.value.bankAccount?.ibanNumber
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length == 1) {
        activeBranchId.value = branchList.value[0].id
        OnChangeBranch(activeBranchId.value)
      }
      isBranchSelectLoading.value = false
    }

    const getBranch = async branchId => {
      const { data } = await ApiService.get("branch/" + branchId)
      cashBookData.value.businessId = data["businessId"]
      activeBusinessId.value = data["businessId"]
      getBranchList(data["businessId"])
    }

    const addCashBook = async () => {
      const data = { ...cashBookData.value }
      if (isBranchActive.value) data.businessId = undefined
      else data.branchId = undefined

      await ApiService.post("cash-book/add", data)
      emit("getlist", activeBusinessId.value, activeBranchId.value)
      router.push({ name: "cash-book-list" })
    }
    const updateCashBook = async () => {
      const data = { ...cashBookData.value, id: props.id }
      if (isBranchActive.value) {
        data.businessId = undefined
        cashBookData.value.businessId = undefined
      } else {
        data.branchId = undefined
        cashBookData.value.branchId = undefined
      }
      await ApiService.post("cash-book/edit", data)

      emit("getlist", cashBookData.value.businessId, cashBookData.value.branchId)
      router.push({ name: "cash-book-list" })
    }

    const addOrUpdate = formEl => {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addCashBook()
          } else {
            updateCashBook()
          }
        }
      })
    }

    onMounted(async () => {
      await getBusinessList()
      await getBankList()
    })
    const OnChangeAccountProperties = (
      value: string | number | undefined,
      type: keyof BankAccount
    ) => {
      if (cashBookData.value.bankAccount) {
        const bankAccount = cashBookData.value.bankAccount
        bankAccount[type as string] = value
      }
    }

    const OnChangeAccountType = () => {
      activeBankId.value = undefined
      branchCode.value = undefined
      branchName.value = undefined
      accountNumber.value = undefined
      ibanNumber.value = undefined
      if (activeBankAccountType.value != 0 && activeBankAccountType.value != undefined) {
        cashBookData.value.bankAccount = {
          bankAccountType: activeBankAccountType.value as BankAccountType,
        } as BankAccount
      } else delete cashBookData.value.bankAccount
    }

    const OnChangeBusiness = businessId => {
      cashBookData.value.branchId = undefined
      activeBranchId.value = undefined
      cashBookData.value.businessId = businessId
      if (isBranchActive.value) getBranchList(businessId)
    }

    const OnChangeBranch = branchId => {
      cashBookData.value.branchId = branchId
    }

    const getBankList = async () => {
      isBankSelectLoading.value = true
      const { data } = await ApiService.get("bank")
      bankList.value = data
      isBankSelectLoading.value = false
    }

    return {
      showDrawer,
      newCashBook: add,
      activeCashBookId: id,
      isBranchActive,
      activeBankId,
      handleClose: props.closed,
      cashBookData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      activeBusinessId,
      activeBranchId,
      rules,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isBankSelectLoading,
      bankAccountTypeList,
      activeBankAccountType,
      OnChangeAccountType,
      OnChangeBusiness,
      OnChangeBranch,
      bankList,
      branchList,
      currencyList,
      OnChangeAccountProperties,
      branchName,
      branchCode,
      accountNumber,
      ibanNumber,
      staticCurrencyList,
    }
  },
})
